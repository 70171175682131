import React from "react"

import newsImages from "components/utils/NewsImages"

import styled from "styled-components"

const Card = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  height: 100%;
  color: white;
  padding: 26px 20px;
  overflow: hidden;
  box-shadow: ${props =>
    props.noShadow ? "none" : "0 20px 35px rgba(10, 25, 45, 0.5)"};
  transition: box-shadow 0.3s ease;
  &:hover {
    box-shadow: ${props =>
      props.noShadow ? "none" : "0 20px 45px rgba(10, 25, 45, 0.85)"};
    & > img {
      transform: scale(1.1);
    }
  }
`

const Date = styled.p`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
`

const Title = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
`

const BackgroundImg = styled.img`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  transition: transform 0.5s ease;
`

const NewsCard = ({ idx, dateString, title, link, ...rest }) => {
  return (
    <Card href={link} rel="noreferrer noopener" target="_blank" {...rest}>
      <BackgroundImg src={newsImages[idx % newsImages.length]} alt="" />
      <Date>{dateString}</Date>
      <Title>{title}</Title>
    </Card>
  )
}

export default NewsCard

export const query = graphql`
  fragment NewsArticle on ContentfulNewsArticle {
    title
    dateString: date(formatString: "MMMM D, YYYY")
    link
  }
`
