import Box from "components/atoms/Box"
import styled from "styled-components"

export default styled(Box)`
  height: 100%;
  .hoverImage img {
    transition: transform 0.4s ease !important;
  }
  &:hover {
    .hoverImage img {
      transform: scale(1.05);
    }
    .team-card-image-overlay {
      opacity: 0.15;
    }
  }
`
