import React from "react"
import { graphql } from "gatsby"

import Reveal from "components/utils/Reveal"
import SectionStyling from "components/atoms/SectionStyling"
import StandardPadding from "components/atoms/StandardPadding"
import GenericGrid from "components/molecules/GenericGrid"

import SectionText from "components/molecules/SectionText"

const NewsGrid = ({ sectionStyling, sectionText, filters, articles, idx }) => {
  return (
    <SectionStyling first={idx === 0} {...sectionStyling}>
      <StandardPadding>
        {sectionText && (
          <Reveal>
            <SectionText {...sectionText} mb={6} maxWidth={42} />
          </Reveal>
        )}
        <GenericGrid items={articles} filters={filters} cardType="news" />
      </StandardPadding>
    </SectionStyling>
  )
}

export default NewsGrid

export const query = graphql`
  fragment NewsGrid on ContentfulNewsGrid {
    sectionStyling {
      ...SectionStyling
    }
    sectionText {
      ...SectionText
    }
    filters {
      name
      tags {
        label
      }
    }
    articles {
      ...NewsArticle
    }
  }
`
