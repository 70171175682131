import React from "react"
import Img from "gatsby-image"

import Box from "components/atoms/Box"

const ImageCard = ({ image, children }) => {
  return (
    <Box className="hoverImage" position="relative">
      <Img
        fluid={image.fluid}
        style={{
          height: 0,
          paddingBottom: "65%",
          width: "100%",
          objectFit: "contain",
        }}
      />
      {children}
    </Box>
  )
}

export default ImageCard
