import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Button from "./Button"

const SubmitButton = ({ ...button }) => {
  return (
    <Button
      width={"144px"}
      height={"44px"}
      {...button}
      type="submit"
      variant="submit"
      value="Subscribe"
      name="subscribe"
      id="mc-embedded-subscribe"
    />
  )
}

export default SubmitButton
