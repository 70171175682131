import React from "react"
import { graphql } from "gatsby"
import axios from "axios"

import Reveal from "components/utils/Reveal"

import SectionStyling from "components/atoms/SectionStyling"
import StandardPadding from "components/atoms/StandardPadding"
import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"

import Form from "components/atoms/Form"
import Input from "components/atoms/Input"
import SubmitButton from "components/atoms/SubmitButton"

import SectionText from "components/molecules/SectionText"

const NewsletterSignup = ({ sectionStyling, sectionText, idx }) => {
  const isDarkBg = sectionStyling.isDarkBackground

  const placeholderButton = {
    text: "Subscribe",
    variant: "primary",
  }

  // const handleSubmit = async e => {
  //   e.preventDefault()
  //   console.log("HANDLE SUBMIT")
  //   // make sure that fields are filled out
  //   if (email !== "") {
  //     const res = await axios({
  //       method: "get",
  //       url:
  //         "https://tencoves.us7.list-manage.com/subscribe/post?u=02bf055c4b37a3886f3d0d3d6&amp;id=5c16e990e5",
  //     })
  //     console.log("SUCCESS", res)
  //     return res
  //   } else {
  //     console.log("Error, please fill out email")
  //   }
  // }

  return (
    <SectionStyling first={idx === 0} {...sectionStyling}>
      <StandardPadding>
        <Reveal>
          <Box>
            <Box maxWidth={560}>
              <SectionText white={isDarkBg} maxWidth={480} {...sectionText} />
            </Box>
            <Form
              method="post"
              target="_blank"
              action="https://tencoves.us7.list-manage.com/subscribe/post?u=02bf055c4b37a3886f3d0d3d6&amp;id=5c16e990e5"
            >
              <Flex flexDirection={{ _: "column", lg: "row" }}>
                <Input
                  width="100%"
                  maxWidth={{ _: "none", xs: "250px", lg: "200px" }}
                  mr={{ _: 0, lg: 3 }}
                  type="text"
                  name="FNAME"
                  id="mce-FNAME"
                  placeholder="First name"
                  required
                />
                <Input
                  width="100%"
                  maxWidth={{ _: "none", xs: "250px", lg: "200px" }}
                  mr={{ _: 0, lg: 3 }}
                  mt={{ _: "10px", lg: 0 }}
                  type="text"
                  name="LNAME"
                  id="mce-LNAME"
                  placeholder="Last name"
                  required
                />
                <Input
                  width="100%"
                  maxWidth={{ _: "none", xs: "250px", lg: "200px" }}
                  mr={{ _: 0, lg: 3 }}
                  mt={{ _: "10px", lg: 0 }}
                  type="email"
                  name="EMAIL"
                  id="mce-EMAIL"
                  placeholder="Enter email"
                  required
                />
                <div
                  style={{ position: "absolute", left: "-5000px" }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_02bf055c4b37a3886f3d0d3d6_5c16e990e5"
                    tabIndex="-1"
                    defaultValue=""
                  />
                </div>
                <SubmitButton
                  mt={{ _: "10px", lg: 0 }}
                  {...placeholderButton}
                />
              </Flex>
            </Form>
          </Box>
        </Reveal>
      </StandardPadding>
    </SectionStyling>
  )
}

export default NewsletterSignup

export const query = graphql`
  fragment NewsletterSignup on ContentfulNewsletterSignup {
    sectionStyling {
      ...SectionStyling
    }
    sectionText {
      ...SectionText
    }
  }
`
