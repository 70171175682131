import React from "react"
import styled from "styled-components"

import { colors } from "utils/styled-constants"

const Indicator = styled.a`
  display: block;
  position: relative;
  box-sizing: content-box;
  padding: 9px;
  width: 7px;
  height: 7px;
  ${props => (props.active ? "" : `opacity: 0.5;`)}
  &:hover {
    opacity: ${props => (props.active ? 1 : 0.9)};
  }
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    height: 7px;
    width: 7px;
    background-color: ${colors.skyBlue};
    border-radius: 100%;
  }
`

const CircleIndicator = ({ ...rest }) => {
  return <Indicator {...rest} />
}

export default CircleIndicator
