import React, { useEffect, useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

import Layout from "components/Layout"
import SEO from "components/Seo"
import SectionTracker from "components/molecules/SectionTracker"

import ImageText from "components/sections/ImageText"
import InfoCards from "components/sections/InfoCards"
import NewsPreview from "components/sections/NewsPreview"
import NewsletterSignup from "components/sections/NewsletterSignup"
import TeamGrid from "components/sections/TeamGrid"
import PortfolioCarousel from "components/sections/PortfolioCarousel"
import PortfolioGrid from "components/sections/PortfolioGrid"
import NewsCarousel from "components/sections/NewsCarousel"
import NewsGrid from "components/sections/NewsGrid"
import Contact from "components/sections/Contact"
import { colorStyle } from "styled-system"
// import Cta from "components/sections/Cta"
// import Twitter from "components/sections/Twitter"
// import ButtonGrid from "components/sections/ButtonGrid"
// import LogoGrid from "components/sections/LogoGrid"
// import ImageCarousel from "components/sections/ImageCarousel"
// import Stats from "components/sections/Stats"
// import CircleGrid from "components/sections/CircleGrid"
// import LogoCarousel from "components/sections/LogoCarousel"
// import TextList from "components/sections/TextList"
// import ContactForm from "components/sections/ContactForm"
// import CompanyGrid from "components/sections/CompanyGrid"
// import HeroCarousel from "components/sections/HeroCarousel"
// import AdvisorsGrid from "components/sections/AdvisorsGrid"

const ScrollTarget = styled.div`
  position: relative;
  top: -80px;
`

const SectionWrapper = ({ idx, children }) => {
  return (
    <div>
      <ScrollTarget id={`section-${idx}`} />
      {children}
    </div>
  )
}

const Page = ({ page, site }) => {
  const [activeSection, setActiveSection] = useState(0)

  page = page.contentfulPage || page
  const heroStyling = page?.sections[0]?.sectionStyling

  useEffect(() => {
    function onScroll() {
      let currActiveSection = 0
      for (let i = 1; i < page.sections.length; i++) {
        let section = document.getElementById(`section-${i}`)
        if (section.getBoundingClientRect().top < window.innerHeight / 2) {
          currActiveSection = i
        } else {
          break
        }
      }
      setActiveSection(currActiveSection)
    }

    if (typeof window !== "undefined") {
      onScroll()
      window.addEventListener("scroll", onScroll)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", onScroll)
      }
    }
  }, [])

  return (
    <Layout
      headerInvert={heroStyling?.isDarkBackground}
      headerBg={heroStyling?.backgroundColor}
      header={site.header}
      footer={site.footer}
    >
      <SEO siteTitle={site.title} title={page.title} />
      {page.title === "Home" && (
        <SectionTracker active={activeSection} sections={page.sections} />
      )}
      {page.sections.map((section, idx) => {
        const key = `${section.__typename}_${idx}`
        const id = idx === 1 ? "second-section" : ""
        switch (section.__typename) {
          case "ContentfulImageText":
            return (
              <SectionWrapper key={idx} idx={idx}>
                <ImageText idx={idx} {...section} key={key} />
              </SectionWrapper>
            )
          case "ContentfulInfoCards":
            return (
              <SectionWrapper key={idx} idx={idx}>
                <InfoCards
                  activeSection={activeSection === idx}
                  idx={idx}
                  {...section}
                  key={key}
                />
              </SectionWrapper>
            )
          case "ContentfulNewsPreview":
            return (
              <SectionWrapper key={idx} idx={idx}>
                <NewsPreview idx={idx} {...section} key={key} />
              </SectionWrapper>
            )
          case "ContentfulNewsletterSignup":
            return (
              <SectionWrapper key={idx} idx={idx}>
                <NewsletterSignup idx={idx} {...section} key={key} />
              </SectionWrapper>
            )
          case "ContentfulTeamGrid":
            return (
              <SectionWrapper key={idx} idx={idx}>
                <TeamGrid idx={idx} {...section} key={key} />
              </SectionWrapper>
            )
          case "ContentfulPortfolioCarousel":
            return (
              <SectionWrapper key={idx} idx={idx}>
                <PortfolioCarousel idx={idx} {...section} key={key} />
              </SectionWrapper>
            )
          case "ContentfulPortfolioGrid":
            return (
              <SectionWrapper key={idx} idx={idx}>
                <PortfolioGrid idx={idx} {...section} key={key} />
              </SectionWrapper>
            )
          case "ContentfulNewsCarousel":
            return (
              <SectionWrapper key={idx} idx={idx}>
                <NewsCarousel idx={idx} {...section} key={key} />
              </SectionWrapper>
            )
          case "ContentfulNewsGrid":
            return (
              <SectionWrapper key={idx} idx={idx}>
                <NewsGrid idx={idx} {...section} key={key} />
              </SectionWrapper>
            )
          case "ContentfulContactSection":
            return (
              <SectionWrapper key={idx} idx={idx}>
                <Contact idx={idx} {...section} key={key} />
              </SectionWrapper>
            )
            {
              /* case "ContentfulCta":
            return (
              <div id={id}>
                <Cta idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulTwitter":
            return (
              <div id={id}>
                <Twitter idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulButtonGrid":
            return (
              <div id={id}>
                <ButtonGrid idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulNewsGrid":
            return (
              <div id={id}>
                <NewsGrid idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulNewsCarousel":
            return (
              <div id={id}>
                <NewsCarousel idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulNewsletterSignup":
            return (
              <div id={id}>
                <NewsletterSignup idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulTeamGrid":
            return (
              <div id={id}>
                <TeamGrid idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulLogoGrid":
            return (
              <div id={id}>
                <LogoGrid idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulImageCarousel":
            return (
              <div id={id}>
                <ImageCarousel idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulStatSection":
            return (
              <div id={id}>
                <Stats idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulContactSection":
            return (
              <div id={id}>
                <Contact idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulCircleGrid":
            return (
              <div id={id}>
                <CircleGrid idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulLogoCarousel":
            return (
              <div id={id}>
                <LogoCarousel idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulPortfolioCarousel":
            return (
              <div id={id}>
                <PortfolioCarousel idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulCompanyGrid":
            return (
              <div id={id}>
                <CompanyGrid idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulTextList":
            return (
              <div id={id}>
                <TextList idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulContactForm":
            return (
              <div id={id}>
                <ContactForm idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulHeroCarousel":
            return (
              <div id={id}>
                <HeroCarousel idx={idx} {...section} key={key} />
              </div>
            )
          case "ContentfulAdvisorsGrid":
            return (
              <div id={id}>
                <AdvisorsGrid idx={idx} {...section} key={key} />
              </div>
            ) */
            }
          default:
            return null
        }
      })}
    </Layout>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query ContentfulSitePageQuery {
        contentfulSite {
          title
          header {
            ... on ContentfulDefaultHeader {
              ...DefaultHeader
            }
          }
          footer {
            ... on ContentfulFooter {
              ...Footer
            }
          }
        }
      }
    `}
    render={data => <Page site={data.contentfulSite} {...props} />}
  />
)

export const query = graphql`
  fragment Page on ContentfulPage {
    title
    sections {
      ... on ContentfulImageText {
        ...ImageText
      }
      ... on ContentfulInfoCards {
        ...InfoCards
      }
      ... on ContentfulNewsPreview {
        ...NewsPreview
      }
      ... on ContentfulNewsletterSignup {
        ...NewsletterSignup
      }
      ... on ContentfulTeamGrid {
        ...TeamGrid
      }
      ... on ContentfulPortfolioCarousel {
        ...PortfolioCarousel
      }
      ... on ContentfulPortfolioGrid {
        ...PortfolioGrid
      }
      ... on ContentfulNewsCarousel {
        ...NewsCarousel
      }
      ... on ContentfulNewsGrid {
        ...NewsGrid
      }
      ... on ContentfulContactSection {
        ...Contact
      }
    }
  }
`
// ... on ContentfulCta {
//   ...Cta
// }
// ... on ContentfulLogoGrid {
//   ...LogoGrid
// }
// ... on ContentfulStatSection {
//   ...Stats
// }
// ... on ContentfulContactSection {
//   ...Contact
// }
// ... on ContentfulLogoCarousel {
//   ...LogoCarousel
// }
// ... on ContentfulTeamGrid {
//   ...TeamGrid
// }
// ... on ContentfulPortfolioCarousel {
//   ...PortfolioCarousel
// }
// ... on ContentfulNewsGrid {
//   ...NewsGrid
// }
// ... on ContentfulNewsCarousel {
//   ...NewsCarousel
// }
// ... on ContentfulCompanyGrid {
//   ...CompanyGrid
// }
// ... on ContentfulTextList {
//   ...TextList
// }
// ... on ContentfulContactForm {
//   ...ContactForm
// }
// ... on ContentfulHeroCarousel {
//   ...HeroCarousel
// }
// ... on ContentfulAdvisorsGrid {
//   ...AdvisorsGrid
// }
// ... on ContentfulTwitter {
//   ...Twitter
// }
