import React from "react"
import { graphql } from "gatsby"

import Reveal from "components/utils/Reveal"

import SectionStyling from "components/atoms/SectionStyling"
import StandardPadding from "components/atoms/StandardPadding"
import Button from "components/atoms/Button"
import Flex from "components/atoms/Flex"
import Box from "components/atoms/Box"
import NewsCard from "components/molecules/NewsCard"

import SectionText from "components/molecules/SectionText"

const NewsPreview = ({
  sectionStyling,
  sectionText,
  newsArticles,
  button,
  idx,
}) => {
  const center = sectionText?.align === "center"
  const isDarkBg = sectionStyling.isDarkBackground
  return (
    <SectionStyling first={idx === 0} {...sectionStyling}>
      <StandardPadding>
        <Reveal>
          <>
            {sectionText && (
              <SectionText
                white={isDarkBg}
                {...sectionText}
                mb={6}
                maxWidth={42}
              />
            )}
            <Flex
              flexDirection={{ _: "column", lg: "row" }}
              alignItems={{ _: "center", lg: "stretch" }}
              justifyContent={{ _: "flex-start", lg: "space-between" }}
            >
              {newsArticles.map((newsArticle, i) => (
                <Box
                  mt={i !== 0 ? { _: 4, lg: 0 } : 0}
                  width="100%"
                  maxWidth={{ _: "400px", lg: "32%" }}
                >
                  <NewsCard idx={i} {...newsArticle} key={i} />
                </Box>
              ))}
            </Flex>
            {button && (
              <Flex
                mt={8}
                flexDirection="column"
                alignItems={center ? "center" : "flex-start"}
              >
                <Button color={isDarkBg ? "teal" : "skyBlue"} {...button} />
              </Flex>
            )}
          </>
        </Reveal>
      </StandardPadding>
    </SectionStyling>
  )
}

export default NewsPreview

export const query = graphql`
  fragment NewsPreview on ContentfulNewsPreview {
    sectionStyling {
      ...SectionStyling
    }
    sectionText {
      ...SectionText
    }
    newsArticles {
      ...NewsArticle
    }
    button {
      ...Button
    }
  }
`
