import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import SectionStyling from "components/atoms/SectionStyling"
import StandardPadding from "components/atoms/StandardPadding"
import Indicators from "components/molecules/Indicators"
import Flex from "components/atoms/Flex"
import Box from "components/atoms/Box"

import Reveal from "components/utils/Reveal"

import {
  ImageCard,
  TextCard,
  OverlappingCardsContainer,
} from "components/molecules/OverlappingCards"
import { Paragraph, Text } from "components/atoms/Typography"
import Button from "components/atoms/Button"

const INTERVAL = 6000

const Logo = styled(Img)`
  max-width: 250px;
  max-height: 64px;
`

const PortfolioCarousel = ({ sectionStyling, companies, idx }) => {
  const [activeIdx, setActiveIdx] = useState(4)

  useEffect(() => {
    let timeout = setTimeout(() => {
      setActiveIdx((activeIdx + 1) % companies.length)
    }, INTERVAL)
    return () => clearTimeout(timeout)
  }, [activeIdx])

  return (
    <SectionStyling first={idx === 0} {...sectionStyling}>
      <StandardPadding py={{ _: 8, lg: 11 }}>
        <OverlappingCardsContainer
          mb={{ _: 0, lg: "20px" }}
          mt={{ _: 0, lg: "-35px" }}
        >
          <TextCard bg="white">
            <Flex
              minHeight={{
                _: "330px",
                xs: "290px",
                sm: "260px",
                md: "230px",
                lg: "260px",
              }}
              height="100%"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box>
                {companies.map((company, i) => {
                  if (i === activeIdx) {
                    return (
                      <Reveal>
                        <Logo
                          fluid={company.logo.fluid}
                          imgStyle={{ width: "auto" }}
                        />
                        <Paragraph
                          mt={6}
                          mb={5}
                          lineHeight="28px"
                          fontSize="24px"
                        >
                          {company.shortDescription.shortDescription}
                        </Paragraph>
                        <Box mb={4}>
                          <Button
                            color="skyBlue"
                            link={{ externalLink: company.websiteLink }}
                            text="View Company"
                            variant="link"
                          />
                        </Box>
                      </Reveal>
                    )
                  } else {
                    return null
                  }
                })}
              </Box>
              <Flex mb="-5px" width="100%" justifyContent="flex-end">
                <Indicators
                  activeIdx={activeIdx}
                  num={companies.length}
                  onChange={setActiveIdx}
                />
              </Flex>
            </Flex>
            {/* <a href={company.websiteLink} target="_blank" rel="noreferrer noopener">
              <Text>View Company</Text>
            </a> */}
          </TextCard>
          <Box>
            {companies.map((company, i) => {
              if (i === activeIdx) {
                return <ImageCard right image={company.featuredImage} />
              } else {
                return null
              }
            })}
          </Box>
        </OverlappingCardsContainer>
      </StandardPadding>
    </SectionStyling>
  )
}

export default PortfolioCarousel

export const query = graphql`
  fragment PortfolioCarousel on ContentfulPortfolioCarousel {
    sectionStyling {
      ...SectionStyling
    }
    companies {
      name
      logo {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      featuredImage {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      shortDescription {
        shortDescription
      }
      websiteLink
    }
  }
`
