import React from "react"
import styled from "styled-components"

import Flex from "components/atoms/Flex"

import { colors } from "utils/styled-constants"

const Indicator = styled.button`
  position: relative;
  box-sizing: content-box;
  padding: 9px;
  width: 7px;
  height: 7px;
  ${props => (props.active ? "" : `opacity: 0.2;`)}
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    height: 7px;
    width: 7px;
    background-color: ${colors.skyBlue};
    border-radius: 100%;
  }
`

const Indicators = ({ num, activeIdx, onChange }) => {
  let Indicators = []
  for (let i = 0; i < num; i++) {
    Indicators.push(
      <Indicator active={i === activeIdx} onClick={() => onChange(i)} />
    )
  }
  return <Flex mx="-9px">{Indicators}</Flex>
}

export default Indicators
