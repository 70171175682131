import React from "react"
import PropTypes from "prop-types"
import { useForm } from "react-hook-form"
//import styles from "styles/components/form.module.scss"

//import classNames from "classnames/bind"
//const cx = classNames.bind(styles)

//https://codesandbox.io/s/react-hook-form-smart-form-component-17k06?file=/src/index.js

// { defaultValues, children, onSubmit, error, align, ...rest }
const Form = ({ children, onSubmit, ...rest }) => {
  // const { register, handleSubmit, errors, formState } = useForm({
  //   mode: "onBlur",
  // })

  // const { isValid, dirtyFields, isSubmitting } = formState
  // const buttonDisabled =
  //   !isValid &&
  //   dirtyFields.size < children.filter(child => child.props.name).length

  return (
    <form onSubmit={() => onSubmit} {...rest}>
      {children}
    </form>
  )
}

export default Form

// Form.propTypes = {
//   defaultValues: PropTypes.object,
//   children: PropTypes.node.isRequired,
//   onSubmit: PropTypes.func.isRequired,
//   error: PropTypes.string,
//   align: PropTypes.oneOf(["left", "right"]),
// }
