const newsImages = [
  "/news-background-1.png",
  "/news-background-2.png",
  "/news-background-3.png",
  "/news-background-4.png",
  "/news-background-5.png",
  "/news-background-6.png",
  "/news-background-7.png",
  "/news-background-8.png",
  "/news-background-9.png",
]

export default newsImages
