import React from "react"
import BgImg from "gatsby-background-image"
import Modal from "components/atoms/Modal"
import styled from "styled-components"

import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"
import { colors } from "utils/styled-constants"

import LinkedinIcon from "images/linkedin-icon-black.svg"
import WebsiteIcon from "images/website-icon.svg"

const StyledModal = styled(Modal)`
  width: 96%;
  max-width: 840px;
  background-color: white;
  box-sizing: border-box;
`

const ItalicP = styled.p`
  font-style: italic;
  font-size: 18px;
  font-weight: 500;
  color: #2d5377;
`

const Paragraph = styled.p`
  font-size: 18px;
  font-weight: 500;
  padding-top: 30px;
  color: #2d5377;
`

const TitleDiv = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: #2d5377;
  margin: 0;
`

const SocialLinksWrapper = styled(Box)`
  display: flex;
  align-items: center;
  height: 30px;
`

const StyledA = styled.a`
  color: ${colors.lightBlue};
  transition: opacity 0.1s ease;
  display: block;
  margin-right: 8px;

  svg {
    height: 24px;
    filter: invert(49%) sepia(12%) saturate(990%) hue-rotate(159deg)
      brightness(95%) contrast(89%);
  }
  &:visited {
    color: ${colors.lightBlue};
  }
  &:hover {
    opacity: 0.5;
  }
`

const TeamMemberModal = ({
  image,
  name,
  positionTitle,
  companyName,
  description,
  linkedInLink,
  websiteLink,
  ...rest
}) => {
  return (
    <StyledModal {...rest}>
      <Flex
        minHeight={{ _: 0, md: "495px" }}
        flexDirection={{ _: "column", md: "row" }}
        height="100%"
      >
        <BgImg fluid={image.fluid}>
          <Box
            height="0px"
            pb={{ _: "100%", md: 0 }}
            width={{ _: "100%", md: "316px" }}
          />
        </BgImg>
        <Box p={{ _: "20px 20px 30px", md: "48px", lg: "48px 92px 48px 48px" }}>
          <TitleDiv>{name}</TitleDiv>
          <ItalicP>
            {positionTitle}, {companyName}
          </ItalicP>
          <Paragraph>{description}</Paragraph>
          <SocialLinksWrapper pt={4}>
            {linkedInLink && (
              <span>
                <StyledA
                  href={linkedInLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <LinkedinIcon />
                </StyledA>
              </span>
            )}
            {websiteLink && (
              <span>
                <StyledA
                  href={websiteLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <WebsiteIcon />
                </StyledA>
              </span>
            )}
          </SocialLinksWrapper>
          {/*
          {linkedInLink && (
            <Box pt={4}>
              <StyledA
                href={linkedInLink}
                target="_blank"
                rel="noreferrer noopener"
              >
                LinkedIn
              </StyledA>
            </Box>
          )}
          {websiteLink && (
            <Box pt={4}>
              <StyledA
                href={websiteLink}
                target="_blank"
                rel="noreferrer noopener"
              >
                Website
              </StyledA>
            </Box>
          )}
          */}
        </Box>
      </Flex>
    </StyledModal>
  )
}

export default TeamMemberModal
