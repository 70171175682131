import React, { useState, useEffect } from "react"
import styled from "styled-components"

import newsImages from "components/utils/NewsImages"

import SectionStyling from "components/atoms/SectionStyling"
import StandardPadding from "components/atoms/StandardPadding"
import Indicators from "components/molecules/Indicators"
import Flex from "components/atoms/Flex"
import Box from "components/atoms/Box"

import {
  ImageCard,
  TextCard,
  OverlappingCardsContainer,
} from "components/molecules/OverlappingCards"
import { Paragraph } from "components/atoms/Typography"
import Button from "components/atoms/Button"
import Reveal from "components/utils/Reveal"

const Date = styled.p`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  color: #96a3b3;
`

const INTERVAL = 6000

const NewsCarousel = ({ sectionStyling, articles, idx }) => {
  const [activeIdx, setActiveIdx] = useState(0)

  useEffect(() => {
    let timeout = setTimeout(() => {
      setActiveIdx((activeIdx + 1) % articles.length)
    }, INTERVAL)
    return () => clearTimeout(timeout)
  }, [activeIdx])

  return (
    <SectionStyling first={idx === 0} {...sectionStyling}>
      <StandardPadding>
        <OverlappingCardsContainer textRight>
          <TextCard right bg="white">
            <Flex
              minHeight={{ _: "230px", lg: "230px" }}
              height="100%"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box>
                {articles.map((article, i) => {
                  if (i === activeIdx) {
                    return (
                      <Reveal>
                        <Date>{article.dateString}</Date>
                        <Paragraph my={5} lineHeight="28px" fontSize="24px">
                          {article.title}
                        </Paragraph>
                        <Box mb={4}>
                          <Button
                            color="skyBlue"
                            link={{ externalLink: article.link }}
                            text="View the article"
                            variant="link"
                          />
                        </Box>
                      </Reveal>
                    )
                  } else {
                    return null
                  }
                })}
              </Box>
              <Flex width="100%" justifyContent="flex-end">
                <Indicators
                  activeIdx={activeIdx}
                  num={articles.length}
                  onChange={setActiveIdx}
                />
              </Flex>
            </Flex>
            {/* <a href={company.websiteLink} target="_blank" rel="noreferrer noopener">
              <Text>View Company</Text>
            </a> */}
          </TextCard>
          <Box>
            {articles.map((article, i) => {
              if (i === activeIdx) {
                return <ImageCard url={newsImages[i]} />
              } else return null
            })}
          </Box>
        </OverlappingCardsContainer>
      </StandardPadding>
    </SectionStyling>
  )
}

export default NewsCarousel

export const query = graphql`
  fragment NewsCarousel on ContentfulNewsCarousel {
    sectionStyling {
      ...SectionStyling
    }
    articles {
      ...NewsArticle
    }
  }
`
