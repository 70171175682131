import React from "react"
import Select, { components } from "react-select"
import ArrowDropdown from "images/arrow-dropdown-gold.svg"
import ArrowDropdownBlue from "images/arrow-dropdown-blue.svg"
import ArrowDropdownLightblue from "images/light-blue-arrow.svg"
import styled from "styled-components"

import { colors } from "utils/styled-constants"

const StyledArrowWrapper = styled("div")`
  transform: ${props => (props.arrowDown ? "rotate(180deg)" : "")};
  transition: transform 0.1s ease;
`
/** Styles for react-select **/
const goldUnderlineStyles = {
  valueContainer: base => ({
    ...base,
    color: colors.gold,
    padding: "0",
  }),
  singleValue: base => ({
    ...base,
    margin: "0",
    width: "100%",
    maxWidth: "none",
    fontSize: "18px",
    color: colors.gold,
    fontWeight: "700",
  }),
  control: base => ({
    ...base,
    minHeight: "none",
    height: "34px",
    border: "none",
    borderBottom: `1px solid ${colors.lighterBlue}`,
    borderRadius: 0,
    color: colors.gold,
    boxShadow: "none",
    backgroundColor: "transparent",
  }),
  placeholder: (base, state) => ({
    ...base,
    fontSize: "18px",
    color: colors.gold,
    fontWeight: "700",
    opacity: "0.5",
  }),
  indicatorSeparator: base => ({ ...base, display: "none" }),
  indicatorsContainer: base => ({
    ...base,
    padding: "0",
    marginRight: "-8px",
  }),
  menu: base => ({
    ...base,
    margin: "0",
    border: "none",
    borderRadius: "0",
    boxShadow: "0 7px 24px rgba(0,0,0,0.16)",
  }),
  menuList: base => ({
    ...base,
    padding: "0",
  }),
  option: (base, state) => ({
    ...base,
    padding: "16px 18px",
    fontSize: "18px",
    color: colors.mediumBlue,
    fontWeight: "700",
    backgroundColor: state.isSelected
      ? colors.lighterBlue
      : state.isFocused
      ? colors.gray
      : "white",
  }),
  multiValue: base => ({ ...base, display: "none" }),
}

const underlineStyles = {
  valueContainer: base => ({
    ...base,
    color: colors.darkBlue,
    padding: "0",
  }),
  singleValue: base => ({
    ...base,
    color: colors.darkBlue,
    fontWeight: "500",
    fontSize: "18px",
    margin: "0",
    width: "100%",
    maxWidth: "none",
  }),
  control: base => ({
    ...base,
    height: "40px",
    border: "none", // removes default border
    borderBottom: `2px solid ${colors.darkBlue}`,
    borderRadius: 0, // need to override default value
    color: colors.darkBlue,
    boxShadow: "none",
    backgroundColor: "transparent",
    paddingLeft: 0,
  }),
  placeholder: (base, state) => ({
    ...base,
    fontWeight: "500",
    fontSize: "18px",
    color: colors.darkBlue, // value shown in dropdown button before selection
  }),
  indicatorSeparator: base => ({ ...base, display: "none" }),
  indicatorsContainer: base => ({
    ...base,
    padding: "0",
    marginRight: "-8px",
  }),
  menu: base => ({
    ...base,
    margin: "0",
    border: "none",
    borderRadius: "0",
    boxShadow: "0 7px 24px rgba(0,0,0,0.16)",
    //maxHeight: "500px",
  }),
  menuList: base => ({
    ...base,
    padding: "0",
  }),
  option: (base, state) => ({
    ...base,
    padding: "16px 18px",
    fontSize: "18px",
    color: colors.mediumBlue,
    fontWeight: "500",
    backgroundColor: state.isSelected
      ? colors.lighterBlue
      : state.isFocused
      ? colors.gray
      : "white",
  }),
  multiValue: base => ({ ...base, display: "none" }),
}

/** Styles for react-select **/
const selectStyles = {
  valueContainer: base => ({
    ...base,
    color: colors.gold,
    padding: "0",
    marginLeft: "16px",
  }),
  singleValue: base => ({
    ...base,
    color: colors.gold,
    margin: "0",
    width: "100%",
    maxWidth: "none",
  }),
  control: base => ({
    ...base,
    height: "40px",
    border: `2px solid ${colors.darkBlue}`,
    color: colors.gold,
    borderRadius: "20px",
    boxShadow: "none",
    backgroundColor: "transparent",
  }),
  placeholder: (base, state) => ({
    ...base,
    color: colors.gold,
  }),
  indicatorSeparator: base => ({ ...base, display: "none" }),
  indicatorsContainer: base => ({
    ...base,
    padding: "0",
    paddingRight: "6px",
  }),
  menu: base => ({
    ...base,
    margin: "0",
    border: "none",
    borderRadius: "0",
    boxShadow: "0 7px 24px rgba(0,0,0,0.16)",
    //maxHeight: "500px",
  }),
  menuList: base => ({
    ...base,
    padding: "0",
  }),
  option: (base, state) => ({
    ...base,
    padding: "16px 18px",
    fontSize: "12px",
    color: colors.gold,
    fontWeight: "500",
    backgroundColor: state.isSelected
      ? colors.darkBlue
      : state.isFocused
      ? colors.mediumBlue
      : colors.lightBlue,
  }),
  multiValue: base => ({ ...base, display: "none" }),
}

const lightBackgroundStyles = {
  valueContainer: base => ({
    ...base,
    color: colors.gold,
    padding: "0",
    marginLeft: "16px",
  }),
  singleValue: base => ({
    ...base,
    color: colors.gold,
    margin: "0",
    width: "100%",
    maxWidth: "none",
  }),
  control: base => ({
    ...base,
    height: "40px",
    border: `2px solid ${colors.darkBlue}`,
    color: colors.darkBlue,
    borderRadius: "20px",
    boxShadow: "none",
    backgroundColor: "transparent",
  }),
  placeholder: (base, state) => ({
    ...base,
    color: colors.darkBlue,
  }),
  indicatorSeparator: base => ({ ...base, display: "none" }),
  indicatorsContainer: base => ({
    ...base,
    padding: "0",
    paddingRight: "6px",
  }),
  menu: base => ({
    ...base,
    margin: "0",
    border: "none",
    borderRadius: "0",
    boxShadow: "0 7px 24px rgba(0,0,0,0.16)",
    //maxHeight: "500px",
  }),
  menuList: base => ({
    ...base,
    padding: "0",
  }),
  option: (base, state) => ({
    ...base,
    padding: "16px 18px",
    fontSize: "12px",
    color: colors.gold,
    fontWeight: "500",
    backgroundColor: state.isSelected
      ? colors.darkBlue
      : state.isFocused
      ? colors.mediumBlue
      : colors.lightBlue,
  }),
  multiValue: base => ({ ...base, display: "none" }),
}

/** Updated components for react-select **/

// Custom arrow that animates based on whether the menu is open
const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <StyledArrowWrapper arrowDown={props.selectProps.menuIsOpen}>
          <ArrowDropdown />
        </StyledArrowWrapper>
      </components.DropdownIndicator>
    )
  )
}

const DropdownIndicatorBlue = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <StyledArrowWrapper arrowDown={props.selectProps.menuIsOpen}>
          <ArrowDropdownBlue />
        </StyledArrowWrapper>
      </components.DropdownIndicator>
    )
  )
}

const DropdownIndicatorLightblue = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <StyledArrowWrapper arrowDown={props.selectProps.menuIsOpen}>
          <ArrowDropdownLightblue />
        </StyledArrowWrapper>
      </components.DropdownIndicator>
    )
  )
}

const GridSelect = React.forwardRef((props, ref) => {
  let {
    value,
    resetLabel,
    options,
    lightBackground,
    useUnderlineStyles,
    useGoldUnderlineStyles,
    ...rest
  } = props
  if (resetLabel) {
    options.unshift({ label: resetLabel, value: resetLabel })
  }

  let styles
  if (useUnderlineStyles) {
    styles = underlineStyles
  } else if (lightBackground) {
    styles = lightBackgroundStyles
  } else if (useGoldUnderlineStyles) {
    styles = goldUnderlineStyles
  } else {
    styles = selectStyles
  }

  return (
    <Select
      ref={ref}
      value={value}
      options={options}
      styles={styles}
      components={
        useGoldUnderlineStyles
          ? { DropdownIndicator: DropdownIndicatorLightblue }
          : lightBackground || useUnderlineStyles
          ? { DropdownIndicator: DropdownIndicatorBlue }
          : { DropdownIndicator }
      }
      isSearchable={false}
      openMenuOnFocus={true}
      maxMenuHeight={400}
      menuShouldScrollIntoView={false}
      {...rest}
    />
  )
})

export default GridSelect
