import React, { cloneElement, useContext } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { ThemeContext } from "styled-components"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import styled from "styled-components"
import {
  space,
  color,
  layout,
  flexbox,
  border,
  shadow,
  position,
  compose,
} from "styled-system"
import css from "@styled-system/css"
import { shouldForwardProp } from "utils/styled-helpers.js"

import Box from "components/atoms/Box"

const TransitionGroupFlex = styled(TransitionGroup).withConfig({
  shouldForwardProp,
})(
  css({
    display: "flex",
  }),
  compose(space, color, layout, flexbox, border, shadow, position)
)

const CSSTransitionBox = styled(CSSTransition).withConfig({
  shouldForwardProp,
})(compose(space, color, layout, flexbox, border, shadow, position))

const TransitionDiv = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  &.item-enter {
    opacity: 0;
    transform: translateY(50px);
  }
  &.item-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 1000ms cubic-bezier(0.21, 0.38, 0, 1);
    transition-property: opacity, transform;
    transition-delay: ${props => props.transitionDelay || 0}ms;
  }
  &.item-appear {
    opacity: 0;
    transform: translateY(50px);
  }
  &.item-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 1000ms cubic-bezier(0.21, 0.38, 0, 1);
    transition-property: opacity, transform;
    transition-delay: ${props => props.transitionDelay || 0}ms;
  }
  &.item-exit {
    opacity: 1;
  }
  &.item-exit-active {
    opacity: 0;
  }
`

/**
 * Grid component using Flexbox
 *
 * Columns: number of columns at different widths
 * Gutter: the spacing between items
 * Align: justify items left or center
 */
const FlexGrid = ({
  children,
  columns,
  gutter,
  pageLength,
  align,
  ...rest
}) => {
  // Need to get the space object from theme
  // so we can divde the spacing by 2 to get the correct spacing bw items
  let { space } = useContext(ThemeContext)

  let styles = {}
  // Spacing based on gutter
  if (typeof gutter === "number") {
    styles.padding = `${space[gutter] / 2}px`
    styles.margin = `-${space[gutter] / 2}px`
  } else if (typeof gutter === "object") {
    styles.padding = {}
    styles.margin = {}
    Object.keys(gutter).forEach(breakpoint => {
      styles.padding[breakpoint] = `${space[gutter[breakpoint]] / 2}px`
      styles.margin[breakpoint] = `-${space[gutter[breakpoint]] / 2}px`
    })
  }

  let containerWidth = "100%"
  if (typeof gutter === "number") {
    containerWidth = `calc(100% + ${space[gutter]}px)`
  } else if (typeof gutter === "object") {
    containerWidth = {}
    Object.keys(gutter).forEach(breakpoint => {
      if (!gutter[breakpoint]) {
        containerWidth[breakpoint] = null
      } else {
        containerWidth[breakpoint] = `calc(100% + ${
          space[gutter[breakpoint]]
        }px)`
      }
    })
  }

  // Width of child items based on columns
  if (typeof columns === "number") {
    styles.width = `${100 / columns}%`
  } else if (typeof columns === "object") {
    styles.width = {}
    Object.keys(columns).forEach(breakpoint => {
      styles.width[breakpoint] = `${100 / columns[breakpoint]}%`
    })
  }

  let justified =
    align === "left"
      ? "flex-start"
      : align === "right"
      ? "space-between"
      : "center"

  // TODO: Use something other than index for the keys
  return (
    <TransitionGroupFlex
      flexWrap="wrap"
      m={styles.margin}
      justifyContent={{ _: "center", sm: "flex-start" }}
      appear={true}
      className="grid"
      width={containerWidth}
      {...rest}
    >
      {children.map((child, i) => {
        if (child) {
          const delay = (i % pageLength) * 50
          return (
            <CSSTransitionBox
              key={child.props.forwardKey}
              timeout={{
                appear: 1000 + delay,
                enter: 1000 + delay,
                exit: 0,
              }}
              idx={i}
              mountOnEnter
              unmountOnExit
              classNames="item"
              maxWidth="400px"
              p={styles.padding}
              width={styles.width}
              height="auto"
            >
              <TransitionDiv transitionDelay={delay}>
                {child.props.height
                  ? child
                  : cloneElement(child, { height: "100%" })}
              </TransitionDiv>
            </CSSTransitionBox>
          )
        } else return null
      })}
    </TransitionGroupFlex>
  )
}

FlexGrid.defaultProps = {
  align: "left",
}

FlexGrid.strapiProps = {
  columns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.objectOf(PropTypes.number),
  ]),
  gutter: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.objectOf(PropTypes.number),
  ]),
  align: PropTypes.oneOf(["left", "center"]).isRequired,
}

FlexGrid.propTypes = {
  children: PropTypes.node.isRequired,
  ...FlexGrid.strapiProps,
}

export default FlexGrid

// export const query = graphql`
//   fragment FlexGrid on ContentfulFlexGrid {
//     columns {
//       _
//       sm
//       md
//       lg
//     }
//     gutter {
//       _
//       sm
//       md
//       lg
//     }
//     align
//   }
// `
