import React from "react"
import { graphql } from "gatsby"

import Reveal from "components/utils/Reveal"
import styled from "styled-components"

import Section from "components/atoms/SectionStyling"
import StandardPadding from "components/atoms/StandardPadding"
import Button from "components/atoms/Button"
import Flex from "components/atoms/Flex"

import InfoCard from "components/molecules/InfoCard"
import SectionText from "components/molecules/SectionText"

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${props =>
    props.rowNum === 1 ? "space-between" : "center"};
  align-items: center;
  padding: 30px 0;
`

const InfoCardsContainer = ({ infoCards, layout }) => {
  if (layout === "row") {
    return <div>Row</div>
  } else {
    const rows = [
      infoCards.slice(0, 1),
      infoCards.slice(1, 3),
      infoCards.slice(3, 4),
    ]
    return (
      <div>
        {rows.map((row, i) => {
          const middle = i === 1
          return (
            <Flex
              width="100%"
              py={{ _: "", md: "30px" }}
              alignItems={{ _: "center", md: "flex-start" }}
              flexDirection={middle ? { _: "column", md: "row" } : "row"}
              justifyContent={middle ? "space-between" : "center"}
              key={i}
            >
              {row.length !== 0 &&
                row.map((infoCard, j) => (
                  <InfoCard {...infoCard} align="center" key={j} />
                ))}
            </Flex>
          )
        })}
      </div>
    )
  }
}

const InfoCards = ({
  sectionStyling,
  sectionText,
  infoCards,
  button,
  layout,
  idx,
  activeSection,
  id,
}) => {
  const center = sectionText?.align === "center"
  return (
    <Section
      activeSection={activeSection}
      first={idx === 0}
      {...sectionStyling}
    >
      <StandardPadding py={{ _: 5, md: 6 }}>
        <Reveal>
          <>
            {sectionText && (
              <SectionText {...sectionText} mb={6} maxWidth={42} />
            )}
            <InfoCardsContainer infoCards={infoCards} layout={layout} />
            {button && (
              <Flex
                mt={8}
                flexDirection="column"
                alignItems={center ? "center" : "flex-start"}
              >
                <Button {...button} />
              </Flex>
            )}
          </>
        </Reveal>
      </StandardPadding>
    </Section>
  )
}

export default InfoCards

export const query = graphql`
  fragment InfoCards on ContentfulInfoCards {
    sectionStyling {
      ...SectionStyling
    }
    sectionText {
      ...SectionText
    }
    layout
    infoCards {
      ...InfoCard
    }
    button {
      ...Button
    }
    cardsBackground
    cardsShadow
  }
`
