import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import SectionStyling from "components/atoms/SectionStyling"
import StandardPadding from "components/atoms/StandardPadding"
import Reveal from "components/utils/Reveal"
import GenericGrid from "components/molecules/GenericGrid"

import SectionText from "components/molecules/SectionText"
import BackgroundIcon from "components/atoms/BackgroundIcon"

const BackgroundImg = styled(Img)`
  top: -110px;
  left: -170px;
  @media (min-width: 768px) {
    left: 0;
  }
`

const TeamGrid = ({
  sectionStyling,
  sectionText,
  teamCards: teamMembers,
  advisors,
  filters,
  idx,
}) => {
  const isDarkBg = sectionStyling.isDarkBackground
  const backgroundImage = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "topo-x2.png" }) {
        childImageSharp {
          fixed(width: 797) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  // combine advisors and team members and add filters
  teamMembers.forEach(member => {
    member.tags = [{ label: "Ten Coves Team" }]
  })
  if (advisors) {
    advisors.forEach(advisor => {
      advisor.tags = [{ label: "Advisors" }]
    })
    filters = [
      {
        name: "Filter",
        tags: [{ label: "Ten Coves Team" }, { label: "Advisors" }],
      },
    ]
  }
  let allItems = advisors ? teamMembers.concat(advisors) : teamMembers

  return (
    <SectionStyling first={idx === 0} {...sectionStyling}>
      <Reveal
        effect="fade-bg-down"
        timingFunction="cubic-bezier(.17,.73,.36,1)"
        delay={800}
        timeout={1500}
      >
        <BackgroundImg
          fixed={backgroundImage.file.childImageSharp.fixed}
          style={{ position: "absolute" }}
        />
      </Reveal>
      <BackgroundIcon right={-48} bottom={48} width={330} height={240} />
      <StandardPadding>
        <Reveal>
          <>
            {sectionText && (
              <SectionText
                white={isDarkBg}
                {...sectionText}
                mb={12}
                maxWidth={42}
              />
            )}
          </>
        </Reveal>
        <GenericGrid
          isDarkBg={isDarkBg}
          items={allItems}
          defaultFilterValues={["Ten Coves Team"]}
          filters={filters ? filters : null}
          cardType="team"
          advisorsGrid={advisors}
        />
      </StandardPadding>
    </SectionStyling>
  )
}

export default TeamGrid

export const query = graphql`
  fragment TeamGrid on ContentfulTeamGrid {
    sectionStyling {
      ...SectionStyling
    }
    sectionText {
      ...SectionText
    }
    teamCards {
      ...TeamCard
    }
    advisors {
      ...TeamCard
    }
  }
`
// filters {
//   name
//   tags {
//     label
//   }
// }
