import React from "react"
import styled from "styled-components"

import Indicator from "components/atoms/CircleIndicator"

const Wrapper = styled.div`
  display: none;
  @media (min-width: 640px) {
    display: block;
  }
  position: fixed;
  z-index: 15;
  top: 47vh;
  left: 10px;
`

const InnerWrapper = styled.div`
  transform-origin: center center;
  transform: translateY(-50%);
`

const SectionTracker = ({ active, sections }) => {
  return (
    <Wrapper>
      <InnerWrapper>
        {sections.map((section, idx) => (
          <Indicator
            aria-label={`Go to section ${idx}`}
            active={active === idx}
            key={idx}
            href={`/#section-${idx}`}
          />
        ))}
      </InnerWrapper>
    </Wrapper>
  )
}

export default SectionTracker
