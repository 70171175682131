import React from "react"
import PropTypes from "prop-types"
import propTypes from "@styled-system/prop-types"
import styled from "styled-components"
import css from "@styled-system/css"
import { space, compose, layout } from "styled-system"
import { shouldForwardProp } from "utils/styled-helpers.js"

const StyledInput = styled("input").withConfig({ shouldForwardProp })(
  compose(space, layout),
  css({
    "-webkit-appearance": "none",
    padding: "16px",
    border: 0,
    outline: "none",
    background: "#f6f7fd",
    height: "44px",
    fontSize: "14px",
  })
)

const Error = styled("div")`
  position: absolute;
  text-align: left;
  color: red;
  font-size: 14px;
  padding: 4px 16px;
  margin-top: 2px;
`

const Input = ({ register, name, label, rules, error, ...rest }) => {
  return (
    <>
      <StyledInput name={name} ref={register && register(rules)} {...rest} />
      {error && <Error>{`Invalid ${label ? label : name}`}</Error>}
    </>
  )
}

export default Input

Input.propTypes = {
  ...Input.strapiProps,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
}

Input.defaultProps = {
  width: 264,
  height: 48,
}
