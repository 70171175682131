import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import propTypes from "@styled-system/prop-types"
import styled from "styled-components"
import classnames from "classnames/bind"

import useContainerDimensions from "hooks/use-container-dimensions"

import WrappedCard from "components/molecules/WrappedCard"
import ImageCard from "components/molecules/ImageCard"
import Box from "components/atoms/Box"
import FlexBox from "components/atoms/Flex"
import { Text, Paragraph } from "components/atoms/Typography"
import Button from "components/atoms/Button"
import { colors } from "utils/styled-constants"
import TeamMemberModal from "components/molecules/TeamMemberModal"
import Link from "components/utils/Link"

const NameCardWrapper = styled("div")`
  position: relative;
  display: flex;
  bottom: 0;
  left: 0;
  width: 100%;
`

const NameCard = ({ name, positionTitle, textColor }) => {
  return (
    <FlexBox
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      padding={4}
      pt={3}
      bg="background.0"
    >
      <Box mt={3} width="100%">
        <Text
          as="p"
          color={textColor || "mediumBlue"}
          variant="grid-item-title"
        >
          {name}
        </Text>
        <Text
          mt={3}
          as="p"
          variant="grid-item-subtitle"
          color={textColor === "white" ? "#E7EEFB" : "mediumBlue"}
        >
          {positionTitle}
        </Text>
      </Box>
    </FlexBox>
  )
}

const ImageOverlay = styled("div")`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.35;
  background-color: ${colors.mediumBlue};
  transition: opacity 0.3s ease;
`

const TeamCard = ({
  name,
  slug,
  positionTitle,
  companyName,
  linkedInLink,
  websiteLink,
  description,
  image,
  align,
  imageShape,
  isDarkBg,
  showNameOnHover,
  advisorsGrid,
}) => {
  const cardRef = useRef(null)
  const { width: cardWidth } = useContainerDimensions(cardRef)

  const center = align === "center"

  let styleOverride = imageShape === "circle"

  let showOnHover = showNameOnHover && !styleOverride

  let square = imageShape === "square"

  let className = "card" + (linkedInLink ? " linked-in-link" : "")

  return (
    <WrappedCard
      style={{
        position: "relative",
        display: styleOverride ? "flex" : "",
        alignItems: "center",
      }}
      className={className}
      height="100%"
      flexDirection="column"
      justifyContent="stretch"
      width={"100%"}
      mx="auto"
      ref={cardRef}
    >
      <Link
        internalLink={{ slug: `team/${slug}` }}
        style={{ width: "100%", textAlign: "left", maxWidth: "400px" }}
      >
        <Box>
          {image && (
            <ImageCard image={image}>
              <ImageOverlay className="team-card-image-overlay" />
            </ImageCard>
          )}
        </Box>
        <NameCard
          name={name}
          textColor={isDarkBg ? "white" : "darkBlue"}
          positionTitle={positionTitle}
          linkedInLink={linkedInLink}
        />
      </Link>
    </WrappedCard>
  )
}

export default TeamCard

TeamCard.strapiProps = {
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string,
  icon: PropTypes.object,
  button: PropTypes.shape(Button.strapiProps),
}

TeamCard.propTypes = {
  ...TeamCard.strapiProps,
  align: PropTypes.oneOf(["left", "center"]).isRequired,
}

export const query = graphql`
  fragment TeamCard on ContentfulTeamMember {
    slug
    image {
      fluid(maxWidth: 500, quality: 100) {
        ...GatsbyContentfulFluid_withWebp
      }
      description
    }
    name
    positionTitle
    linkedInLink
    description {
      description
    }
  }
`
