import React from "react"
import { graphql } from "gatsby"

import Reveal from "components/utils/Reveal"

import SectionStyling from "components/atoms/SectionStyling"
import StandardPadding from "components/atoms/StandardPadding"
import GenericGrid from "components/molecules/GenericGrid"
import Box from "components/atoms/Box"

import SectionText from "components/molecules/SectionText"

const PortfolioGrid = ({
  sectionStyling,
  filters,
  sectionText,
  companies,
  idx,
}) => {
  const isDarkBg = sectionStyling.isDarkBackground
  if (filters && filters[0].tags[0].label !== "All") {
    filters[0].tags.unshift({ label: "All" })
  }
  return (
    <SectionStyling first={idx === 0} {...sectionStyling}>
      <StandardPadding>
        {sectionText && (
          <Reveal>
            <SectionText {...sectionText} mb={8} maxWidth={42} />
          </Reveal>
        )}
        <Box maxWidth={960} mx="auto">
          <GenericGrid
            centerFilters
            isDarkBg={isDarkBg}
            defaultFilterValues={["All"]}
            filters={filters || null}
            cardType="portfolio"
            items={companies}
            pageLength={10000}
          />
        </Box>
      </StandardPadding>
    </SectionStyling>
  )
}

export default PortfolioGrid

export const query = graphql`
  fragment PortfolioGrid on ContentfulPortfolioGrid {
    sectionStyling {
      ...SectionStyling
    }
    sectionText {
      ...SectionText
    }
    filters {
      name
      tags {
        label
      }
    }
    companies {
      ...PortfolioCompany
    }
  }
`
