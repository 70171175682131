import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { color } from "styled-system"

import Reveal from "components/utils/Reveal"

import SectionStyling from "components/atoms/SectionStyling"
import StandardPadding from "components/atoms/StandardPadding"
import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"
import { Title, Paragraph } from "components/atoms/Typography"

const StyledAnchor = styled.a`
  text-decoration: none;
  transition: opacity 0.1s ease;
  &:hover {
    opacity: 0.5;
  }
  color: inherit;
`

const Contact = ({ sectionStyling, contactInfo, idx }) => {
  const isDarkBg = sectionStyling.isDarkBackground
  const color = isDarkBg ? "white" : "darkBlue"
  const textAlign = "left"

  return (
    <SectionStyling minHeight={idx === 0} first={idx === 0} {...sectionStyling}>
      <StandardPadding>
        <Box py={8}>
          <Reveal>
            <Flex
              flexDirection={{ _: "column", sm: "column" }}
              justifyContent="space-between"
              flexDirection={"row"}
              alignItems="center"
            >
              {contactInfo.map(({ location, address, email }, i) => (
                <Box key={i} width={{ _: "100%", sm: "40%" }}>
                  <Title mb={3} textAlign={textAlign} color={color}>
                    {location}
                  </Title>
                  {address && (
                    <Paragraph mb={2} textAlign={textAlign} color={color}>
                      {address}
                    </Paragraph>
                  )}
                  {email && (
                    <Paragraph textAlign={textAlign} color={color}>
                      <StyledAnchor href={`mailto:${email}`}>
                        {email}
                      </StyledAnchor>
                    </Paragraph>
                  )}
                </Box>
              ))}
            </Flex>
          </Reveal>
        </Box>
      </StandardPadding>
    </SectionStyling>
  )
}

export default Contact

// Contact.propTypes = {
//   id: PropTypes.string.isRequired,
//   section: PropTypes.shape(Section.strapiProps).isRequired,
//   sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
//   contactInfo: PropTypes.shape(ContactInfo.strapiProps),
//   button: PropTypes.shape(Button.strapiProps),
//   image: PropTypes.object,
//   imageLocation: PropTypes.oneOf(["right", "left"]),
// }

export const query = graphql`
  fragment Contact on ContentfulContactSection {
    sectionStyling {
      ...SectionStyling
    }
    contactInfo {
      location
      address
      email
    }
  }
`
