import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Card from "components/atoms/Card"
import { Title, Paragraph } from "components/atoms/Typography"
import Button from "components/atoms/Button"
import Icon from "components/atoms/Icon"

const InfoCard = ({ title, paragraph, icon, align, button, bg, boxShadow }) => {
  const center = align === "center"
  return (
    <Card
      px={4}
      py={6}
      height="100%"
      maxWidth="100%"
      width="340px"
      bg={bg ? bg : undefined} // send undefined so the default props are used
      display="flex"
      flexDirection="column"
      alignItems={center ? "center" : null}
      boxShadow="none"
    >
      {icon && <Icon {...icon} mb={4} />}
      <Title variant="h3" as="p" mb={3}>
        {title}
      </Title>
      {paragraph && (
        <Paragraph
          fontSize="18px"
          lineHeight="26px"
          textAlign={center ? "center" : null}
        >
          {paragraph}
        </Paragraph>
      )}
      {button && <Button {...button} mt={6} />}
    </Card>
  )
}

export default InfoCard

InfoCard.strapiProps = {
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string,
  icon: PropTypes.shape(Icon.strapiProps),
  button: PropTypes.shape(Button.strapiProps),
}

InfoCard.propTypes = {
  ...InfoCard.strapiProps,
  align: PropTypes.oneOf(["left", "center"]).isRequired,
  bg: PropTypes.string,
  boxShadow: PropTypes.string,
}

export const query = graphql`
  fragment InfoCard on ContentfulInfoCard {
    title
    paragraph
    icon {
      ...Icon
    }
    button {
      ...Button
    }
  }
`
