import React, { useEffect, useState } from "react"
import styled from "styled-components"

import Box from "components/atoms/Box"
import FlexGridBlog from "components/atoms/FlexGridBlog"
import Flex from "components/atoms/Flex"

import LogoItem from "components/molecules/LogoItem"
import TeamCard from "components/molecules/TeamCard"
import NewsCard from "components/molecules/NewsCard"

import Select from "components/atoms/Select"
import Button from "components/atoms/Button"

import useBreakpoint from "hooks/use-breakpoint"
import { colors, breakpoints } from "utils/styled-constants"

const FilterButton = styled.button`
  color: ${colors.teal};
  padding: 8px 0;
  margin-right: 20px;
  &:last-child {
    margin-right: 0px;
  }
  ${props =>
    props.active ? `border-bottom: 2px solid ${colors.teal};` : `opacity: 0.5`}
`

const GenericGridWrapper = props => {
  let flexGrid = props.flexGrid
  if (!flexGrid) {
    flexGrid = {
      columns: {
        _: 1,
        lg: 3,
        sm: 2,
      },
      gutter: {
        _: 4,
        lg: 5,
      },
      align: "left",
      pageLengths: props.pageLength || {
        _: 6,
        lg: 18,
        sm: 12,
      },
    }
  }
  const pageLengths = flexGrid.pageLengths
  const isClient = typeof window === "object"

  const is_ = useBreakpoint(breakpoints.xs)
  const isXs = useBreakpoint(breakpoints.sm)
  const isSm = useBreakpoint(breakpoints.md)
  const isMd = useBreakpoint(breakpoints.lg)
  const isLg = useBreakpoint(breakpoints.xl)
  const [hasWindow, setHasWindow] = useState(false)

  function getPageLength(breakpoint) {
    switch (breakpoint) {
      case "xl":
        if (pageLengths.xl) {
          return pageLengths.xl
        }
      case "lg":
        if (pageLengths.lg) {
          return pageLengths.lg
        }
      case "md":
        if (pageLengths.md) {
          return pageLengths.md
        }
      case "sm":
        if (pageLengths.sm) {
          return pageLengths.sm
        }
      case "xs":
        if (pageLengths.xs) {
          return pageLengths.xs
        }
      case "_":
        if (pageLengths._) {
          return pageLengths._
        }
      default:
        return 1000
    }
  }

  useEffect(() => {
    if (typeof window === "object") {
      setHasWindow(true)
    } else {
      setHasWindow(false)
    }
  }, [isClient])

  if (hasWindow) {
    if (is_) {
      //console.log("_")
      return (
        <GenericGrid
          pageLength={getPageLength("_")}
          flexGrid={flexGrid}
          {...props}
        />
      )
    } else if (isXs) {
      //console.log("xs")
      return (
        <GenericGrid
          pageLength={getPageLength("xs")}
          flexGrid={flexGrid}
          {...props}
        />
      )
    } else if (isSm) {
      //console.log("sm")
      return (
        <GenericGrid
          pageLength={getPageLength("sm")}
          flexGrid={flexGrid}
          {...props}
        />
      )
    } else if (isMd) {
      //console.log("md")
      return (
        <GenericGrid
          pageLength={getPageLength("md")}
          flexGrid={flexGrid}
          {...props}
        />
      )
    } else if (isLg) {
      //console.log("lg")
      return (
        <GenericGrid
          pageLength={getPageLength("lg")}
          flexGrid={flexGrid}
          {...props}
        />
      )
    } else {
      // isXl
      //console.log("xl")
      return (
        <GenericGrid
          pageLength={getPageLength("xl")}
          flexGrid={flexGrid}
          {...props}
        />
      )
    }
  } else {
    return null
  }
}

const GenericGrid = ({
  flexGrid,
  items,
  centerFilters,
  filters,
  cardType,
  isDarkBg,
  lightBackground,
  defaultFilterValues,
  pageLength,
  advisorsGrid,
  textColor,
  button,
}) => {
  const [numLoaded, setNumLoaded] = useState(pageLength)
  const [allLoaded, setAllLoaded] = useState(pageLength >= items.length)
  const [filterValues, setFilterValues] = useState(
    defaultFilterValues || filters?.map(() => null) || null
  )

  function loadMore() {
    const newNum = numLoaded + pageLength
    setNumLoaded(newNum)
    setAllLoaded(newNum >= items.length)
  }

  const [filteredItems, setFilteredItems] = useState(items)
  function filterItems() {
    let newFilteredItems = items.map(item => item)
    if (filters) {
      filters.forEach((_filter, i) => {
        if (!filterValues[i] || filterValues[i] === "All") return true
        newFilteredItems = newFilteredItems.filter(({ tags }) => {
          if (!tags) return false
          const destructuredTags = tags.map(({ label }) => label)
          return destructuredTags.indexOf(filterValues[i]) + 1
        })
      })
    }
    setFilteredItems(newFilteredItems)
  }

  useEffect(() => {
    filterItems()
    setNumLoaded(pageLength)
  }, [filterValues, pageLength])

  useEffect(() => {
    setAllLoaded(pageLength >= filteredItems.length)
  }, [filteredItems])

  // we use this to justify-content center on 1 column
  // and justify-column flex-start on multiple columns
  let justifyContentObject = { _: "center" }
  if (flexGrid?.columns) {
    const columns = flexGrid.columns
    if (columns._ === 1) {
      const keys = Object.keys(breakpoints)
      // find first column that isn't 1
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        if (columns[key] > 1) {
          justifyContentObject[key] = "flex-start"
          break
        }
      }
    } else {
      justifyContentObject = "flex-start"
    }
  }

  console.log("filterValues", filterValues)
  return (
    <>
      {filters && (
        <Flex
          width="100%"
          justifyContent={centerFilters ? "center" : "flex-start"}
          flexWrap="wrap"
          pb={7}
        >
          {filters.map((filter, i) => {
            const options = filter.tags.map(({ label }) => ({
              label,
              value: label,
            }))
            return (
              <Box>
                {options.map((option, j) => (
                  <FilterButton
                    active={filterValues[i] === option.label}
                    key={j}
                    onClick={() => {
                      let newFilterValues = filterValues.map((value, k) =>
                        k === i ? option.label : value
                      )
                      setFilterValues(newFilterValues)
                    }}
                  >
                    {option.label}
                  </FilterButton>
                ))}
                {/* <Select
                  lightBackground={lightBackground}
                  resetLabel={noAllFilter ? "All" : null}
                  placeholder={filter.name}
                  options={options}
                  value={
                    filterValues[i]
                      ? { label: filterValues[i], value: filterValues[i] }
                      : null
                  }
                  onChange={({ label }) => {
                    let newFilterValues = filterValues.map((value, j) =>
                      j === i ? label : value
                    )
                    setFilterValues(newFilterValues)
                  }}
                /> */}
              </Box>
            )
          })}
        </Flex>
      )}
      <FlexGridBlog
        pageLength={pageLength}
        justifyContent={justifyContentObject}
        {...flexGrid}
      >
        {filteredItems &&
          filteredItems
            .filter((_item, i) => i < numLoaded)
            .map((item, i) => {
              if (cardType === "news") {
                return (
                  <NewsCard
                    noShadow
                    idx={i}
                    key={i}
                    forwardKey={`${i}${item.title}`}
                    {...item}
                    isDarkBg={isDarkBg}
                  />
                )
              } else if (cardType === "portfolio") {
                return <LogoItem forwardKey={`${i}${item.name}`} {...item} />
              }
              return (
                <TeamCard
                  key={i}
                  forwardKey={`${i}${item.name}`}
                  {...item}
                  isDarkBg={isDarkBg}
                  cardShadow
                  advisorsGrid={advisorsGrid}
                  textColor={textColor}
                />
              )
            })}
      </FlexGridBlog>
      {!allLoaded && (
        <Flex mt={9} justifyContent="center">
          <Button
            justifyContent="center"
            variant="link"
            color={isDarkBg ? "teal" : "skyBlue"}
            noArrow
            text="Load More"
            onClick={loadMore}
          />
        </Flex>
      )}
      {allLoaded && button && (
        <Flex mt={9} justifyContent="center">
          <Button {...button} />
        </Flex>
      )}
    </>
  )
}

GenericGrid.defaultProps = {
  filters: [],
}

export default GenericGridWrapper
